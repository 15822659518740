import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Card, Button } from 'bv-components';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';
import { isEmpty } from 'underscore';
import PasswordWithToggle from 'SharedComponents/password/components/password';
import Period from './fields/period';
import CausesDropdown from './fields/causes_dropdown';
import ConfirmationModal from './confirmation_modal';
import { NONE_SET_SLIDER_ELEMENT, getSelfExcludeBtnLabel, requiredValidation } from '../helpers';

const showPasswordError = ({
  touched, error, submitError, dirtySinceLastSubmit,
}) => (
  (touched && error) || (!dirtySinceLastSubmit && submitError)
);

const FormView = ({
  selfExclusionPeriods,
  selfExclusionCauses,
  showSelfExclusionPeriods,
  onSubmit,
  showConfirmationModal,
  confirmationModalOpened,
}) => {
  const selfExclusionSliderElements = [NONE_SET_SLIDER_ELEMENT, ...selfExclusionPeriods];

  return (
    <Card>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          period: selfExclusionSliderElements[0].id,
        }}
      >
        {({
          handleSubmit, submitting, errors, values, submitErrors, dirtySinceLastSubmit,
        }) => (
          <form className="self-exclusion-form" onSubmit={handleSubmit}>
            {showSelfExclusionPeriods ? (
              <Field
                name="period"
                component={Period}
                selfExclusionPeriods={selfExclusionSliderElements}
                validate={requiredValidation}
              />
            ) : null}
            {selfExclusionCauses.length > 0 && (
              <div className="self-exclusion-form__row">
                <label htmlFor="cause">
                  {t('javascript.self_exclusion.causes.why_do_you_want_to_exclude')}
                </label>
                <Field
                  name="cause"
                  component={CausesDropdown}
                  selfExclusionCauses={selfExclusionCauses}
                  validate={requiredValidation}
                />
              </div>
            )}
            {!bvVar('skipIdentityConfirmationInSgPortal') && (
              <div className="self-exclusion-form__row">
                <Field
                  id="password"
                  name="password"
                  formName="selfExclude"
                  component={PasswordWithToggle}
                  validate={requiredValidation}
                  withToggle
                  showError={showPasswordError}
                  placeholder={t('javascript.self_exclusion.enter_password_to_confirm')}
                />
              </div>
            )}
            <div className="self-exclusion-form__row">
              <Button
                primary
                type="submit"
                label={getSelfExcludeBtnLabel(
                  values.period,
                  showSelfExclusionPeriods,
                  selfExclusionPeriods,
                )}
                className="self-exclusion-form__submit"
                disabled={
                  !isEmpty(errors)
                  || (!isEmpty(submitErrors) && !dirtySinceLastSubmit)
                  || submitting
                  || (showSelfExclusionPeriods && (values.period === NONE_SET_SLIDER_ELEMENT.id))
                }
              />
            </div>
            {confirmationModalOpened && (
              <ConfirmationModal
                handleNoBtnClick={() => showConfirmationModal(false)}
                handleYesBtnClick={handleSubmit}
              />
            )}
          </form>
        )}
      </Form>
    </Card>
  );
};

FormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  confirmationModalOpened: PropTypes.bool.isRequired,
  showConfirmationModal: PropTypes.func.isRequired,
  selfExclusionPeriods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
    }),
  ).isRequired,
  selfExclusionCauses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  showSelfExclusionPeriods: PropTypes.bool.isRequired,
};

export default FormView;
