import { connect } from 'react-redux';
import { useLoggedIn } from 'bv-hooks';
import {
  fetchSelfExclusionPeriods,
  selfExcludeUser,
  closeSubmitResultModal,
  fetchSelfExclusionCauses,
} from '../duck';
import AppContainer from './app_container';
import LoggedOut from './logged_out';

const AppRedux = (props) => {
  const loggedIn = useLoggedIn();

  return loggedIn
    ? <AppContainer {...props} />
    : <LoggedOut {...props} />;
};

const mapStateToProps = (state) => {
  const {
    selfExclusion: {
      selfExclusionPeriods,
      showSelfExclusionPeriods,
      selfExclusionCauses,
      selfExclusionPeriodsLoaded,
      selfExclusionCausesLoaded,
      userIsSelfExcluding,
      userSelfExclusionFailed,
      error,
    },
  } = state;

  return {
    selfExclusionPeriods,
    showSelfExclusionPeriods,
    selfExclusionCauses,
    selfExclusionPeriodsLoaded,
    selfExclusionCausesLoaded,
    userIsSelfExcluding,
    userSelfExclusionFailed,
    error,
  };
};

const mapDispatchToProps = {
  fetchSelfExclusionPeriods,
  selfExcludeUser,
  closeSubmitResultModal,
  fetchSelfExclusionCauses,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRedux);
