import AppRedux from './components/app_redux';
import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({
  selfExclusion: reducer,
});

const AppContainer = (props) => (
  <AppRedux {...props} />
);

export default AppContainer;
