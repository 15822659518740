import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const FailureSubmitModal = ({ error, closeModal }) => (
  <Modal
    title={t('javascript.self_exclusion.error_title')}
    danger
    iconClosable
    icon={false}
    onCloseClick={closeModal}
  >
    <p>{error}</p>
  </Modal>
);

FailureSubmitModal.propTypes = {
  error: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default FailureSubmitModal;
