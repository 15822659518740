import PropTypes from 'prop-types';
import { SliderSelector } from 'bv-components';

const Period = ({ input: { onChange, value }, selfExclusionPeriods }) => (
  <div className="self-exclusion-form__row">
    <SliderSelector
      selections={selfExclusionPeriods}
      selectedId={value}
      onChange={({ target: { value: val } }) => onChange(Number(val) || val)}
      triggerWhileScrolling={false}
      isWiderItem
    />
  </div>
);

Period.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  selfExclusionPeriods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default Period;
