import { t } from 'bv-i18n';

export const NONE_SET_SLIDER_ELEMENT = { id: 'NONE_SET', title: '-' };

export const getSelfExcludeBtnLabel = (
  periodId,
  showSelfExclusionPeriods,
  selfExclusionPeriods,
) => {
  if (!showSelfExclusionPeriods) return t('javascript.self_exclusion.button');
  return t('javascript.self_exclusion.self_exclude_for', {
    value: (periodId === NONE_SET_SLIDER_ELEMENT.id) ? '...' : selfExclusionPeriods.find(
      (period) => period.id === periodId,
    ).title,
  });
};

export const requiredValidation = (value) => (value ? undefined : t('javascript.required_field'));
