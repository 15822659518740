import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { Spinner } from 'bv-components';
import { asyncPasswordValidation } from 'validations';
import { withScrollToTop } from 'bv-hocs';
import { setLocation } from 'bv-helpers/location';
import FormView from './form_view';
import FailureSubmitModal from './failure_submit_modal';
import TitleWithSummary from './title_with_summary';

const AppContainer = ({
  selfExclusionPeriods,
  showSelfExclusionPeriods,
  selfExclusionCauses,
  closeSubmitResultModal,
  fetchSelfExclusionPeriods,
  fetchSelfExclusionCauses,
  showTitleWithSummary,
  selfExclusionPeriodsLoaded,
  selfExclusionCausesLoaded,
  selfExcludeUser,
  userIsSelfExcluding,
  userSelfExclusionFailed,
  error,
  footer,
  isGamStopBrand,
  siteName,
}) => {
  useEffect(() => {
    fetchSelfExclusionPeriods();
    fetchSelfExclusionCauses();
  }, []);

  const [confirmationModalOpened, showConfirmationModal] = useState(false);

  const handleSubmit = async (values) => {
    if (confirmationModalOpened) {
      selfExcludeUser(values, () => setLocation('/login'));
      showConfirmationModal(false);
    } else if (bvVar('skipIdentityConfirmationInSgPortal')) {
      showConfirmationModal(true);
    } else {
      const { success, message } = await asyncPasswordValidation(values.password);
      if (success) {
        showConfirmationModal(true);
      } else {
        return {
          password: message,
        };
      }
    }
    return {};
  };

  if (
    !selfExclusionPeriodsLoaded
    || !selfExclusionCausesLoaded
    || userIsSelfExcluding
  ) return <Spinner />;

  return (
    <>
      {showTitleWithSummary && (
        <TitleWithSummary isGamStopBrand={isGamStopBrand} siteName={siteName} />
      )}
      <FormView
        onSubmit={handleSubmit}
        showConfirmationModal={showConfirmationModal}
        confirmationModalOpened={confirmationModalOpened}
        selfExclusionPeriods={selfExclusionPeriods}
        showSelfExclusionPeriods={showSelfExclusionPeriods}
        selfExclusionCauses={selfExclusionCauses}
      />
      {footer}
      {userSelfExclusionFailed && (
        <FailureSubmitModal error={error} closeModal={closeSubmitResultModal} />
      )}
    </>
  );
};

AppContainer.propTypes = {
  selfExclusionPeriods: PropTypes.arrayOf(
    PropTypes.instanceOf(Object),
  ).isRequired,
  showSelfExclusionPeriods: PropTypes.bool.isRequired,
  selfExclusionCauses: PropTypes.arrayOf(
    PropTypes.instanceOf(Object),
  ).isRequired,
  showTitleWithSummary: PropTypes.bool,
  selfExclusionPeriodsLoaded: PropTypes.bool.isRequired,
  selfExclusionCausesLoaded: PropTypes.bool.isRequired,
  fetchSelfExclusionPeriods: PropTypes.func.isRequired,
  fetchSelfExclusionCauses: PropTypes.func.isRequired,
  selfExcludeUser: PropTypes.func.isRequired,
  userIsSelfExcluding: PropTypes.bool.isRequired,
  userSelfExclusionFailed: PropTypes.bool.isRequired,
  error: PropTypes.string,
  closeSubmitResultModal: PropTypes.func.isRequired,
  footer: PropTypes.element,
  isGamStopBrand: PropTypes.bool,
  siteName: PropTypes.string,
};

AppContainer.defaultProps = {
  showTitleWithSummary: true,
  footer: null,
  isGamStopBrand: bvVar('isGamStopBrand'),
  siteName: bvVar('siteName'),
  error: null,
};

export default withScrollToTop()(AppContainer);
