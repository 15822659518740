import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import Summary from './summary';

const TitleWithSummary = ({ isGamStopBrand, siteName }) => (
  <>
    <h3>{t('javascript.self_exclusion.title')}</h3>
    <Summary wide={false} isGamStopBrand={isGamStopBrand} siteName={siteName} />
  </>
);

TitleWithSummary.propTypes = {
  isGamStopBrand: PropTypes.bool.isRequired,
  siteName: PropTypes.string.isRequired,
};

export default TitleWithSummary;
