import PropTypes from 'prop-types';
import { Select } from 'bv-components';
import { t } from 'bv-i18n';

const CausesDropdown = (props) => {
  const {
    input: { onChange, value },
    selfExclusionCauses,
  } = props;

  return (
    <div className="bvs-form-group">
      <Select
        includeBlank={t('javascript.self_exclusion.causes.select_a_cause')}
        options={selfExclusionCauses}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

CausesDropdown.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  selfExclusionCauses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default CausesDropdown;
