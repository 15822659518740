import { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

class SelfExcludeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wide: props.wide,
    };

    this.toggleClass = this.toggleClass.bind(this);
  }

  toggleClass() {
    this.setState((prevState) => ({
      wide: !prevState.wide,
    }));
  }

  option(key) {
    const { siteName } = this.props;

    return <p>{t(key, { current_site_name: siteName })}</p>;
  }

  render() {
    const { siteName, isGamStopBrand } = this.props;
    const { wide } = this.state;

    return (
      <div>
        <p>
          {t('javascript.self_exclusion.self_exclude_p_1', {
            current_site_name: siteName,
            current_site_name2: siteName,
          })}
        </p>
        {this.option('javascript.self_exclusion.self_exclude_p_2')}
        {wide && (
          <div>
            {this.option('javascript.self_exclusion.self_exclude_p_3')}
            {this.option('javascript.self_exclusion.self_exclude_p_4')}
            {this.option('javascript.self_exclusion.self_exclude_p_4_b')}
            {this.option('javascript.self_exclusion.self_exclude_p_5')}

            {isGamStopBrand && (
              <div>
                {this.option('javascript.self_exclusion.self_exclude_p_6')}
                {this.option('javascript.self_exclusion.self_exclude_p_7')}
              </div>
            )}
          </div>
        )}

        {!wide && (
          <div
            className={`self-exclusion-form__more bvs-link ${wide ? 'visible' : ''}`}
            onClick={this.toggleClass}
          >
            {t('javascript.self_exclusion.view_more')}
          </div>
        )}

        <hr className="separator" />
      </div>
    );
  }
}

SelfExcludeSummary.propTypes = {
  wide: PropTypes.bool,
  isGamStopBrand: PropTypes.bool.isRequired,
  siteName: PropTypes.string.isRequired,
};

SelfExcludeSummary.defaultProps = {
  wide: false,
};

export default SelfExcludeSummary;
