import { getJSON, postJSON } from 'bv-fetch';

export const apiFetchSelfExclusionPeriods = () => getJSON('/api/self_exclusion_check/periods');
export const apiFetchSelfExclusionCauses = () => getJSON('/api/self_exclusion_check/causes');

export const apiSelfExcludeUser = ({ password, period, cause }) => (
  postJSON('/api/self_exclusion/account/close', {
    time_period_id: period,
    cause_ids: [cause],
    ...password && { password },
  })
);
