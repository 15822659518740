import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Card, Button } from 'bv-components';
import TitleWithSummary from './title_with_summary';

const LoggedOut = ({ showTitleWithSummary, isGamStopBrand, siteName }) => (
  <>
    {showTitleWithSummary && (
      <TitleWithSummary isGamStopBrand={isGamStopBrand} siteName={siteName} />
    )}
    <Card>
      <Button
        label={t('login')}
        href="/login"
        primary
        className="self-exclusion__login-btn"
      />
    </Card>
  </>
);

LoggedOut.propTypes = {
  showTitleWithSummary: PropTypes.bool.isRequired,
  isGamStopBrand: PropTypes.bool.isRequired,
  siteName: PropTypes.string.isRequired,
};

export default LoggedOut;
